export default {
    title: 'Cumulative Checkout Abandonment Rate',
    location: '/business/cumulative-checkout-abandonment-rate',
    tabs: [
        {
            name: 'By Country',
            link: `/business/cumulative-checkout-abandonment-rate`
        },
        {
            name: 'Trend',
            link: `/business/cumulative-checkout-abandonment-rate/:market`
        }
    ]
}
